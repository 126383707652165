import store from '@/store';
import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';

import { filterMarkerMap, jobListMap } from '@/api/Map';
import { CountType, ICountType, JobType } from '@/types/ProfessionType';
import { jobListItemsSearch } from '@/api/Profession';

export interface IMapState {
  mapJobs: any
  mapConfig: {
    center: {
      lat: number
      lng: number
    }
    scrollwheel: boolean
    zoom: number
    data_date: string
    mapTypeId: number
  }
  jobMapPagination: any
  selectedRegionJobsIds: any
  visibleMap: boolean
  noticeMapRedirect: boolean
  mapMarkersIds: any
  searchJobProfession: any
  searchJobLocation: any
  employmentCount: ICountType
}

interface IJobs {
  amt: number | undefined
  firms?: []
  jobs: []
}

@Module({ dynamic: true, store, name: 'MapModule' })
class Map extends VuexModule implements IMapState {
  mapJobs = [];

  employmentCount = {
    fulltime: null,
    parttime: null,
    praktikum: null,
    skill_level2: null,
    skill_level3: null,
  }

  public mapConfig = {
    center: {
      lat: 0,
      lng: 0,
    },
    scrollwheel: false,
    zoom: 0,
    data_date: '',
    mapTypeId: 0,
  };

  selectedRegionJobsIds = null;

  visibleMap = false;

  noticeMapRedirect = false;

  mapMarkersIds = [];

  jobMapPagination = {
    per_page: 20,
    page: 1,
  } as any;

  jobMapFilter = {
    dejob: 0,
    recruit: 0,
    tmpjob: 0,
    workjob: 0,
    edujob: 0,
    praktikum: 0,
    fulltime: 0,
    parttime: 0,
    minijob: 0,
    seasonal: 0,
    holiday: 0,
    time_factor: 0,
    specialjob: 0,
    disability_friendly: 0,
    skill_level1: 0,
    skill_level2: 0,
    skill_level3: 0,
    partner_jobs_only: 0,
    other_jobs: 0,
    cluster_id: 0,
  } as any;

  jobMapParams = {
    id: 0,
    jobnews_type: 1,
  } as any;

  mapClusterParams = {
    cluster_id: null,
  } as any;

  jobMapSearch = {
    profession_id: null,
    location_id: null,
    idcomp: null,
    radius: 0,
    jobnews_type: null,
    fulltext: 0,
    q: '',
    mode: 'AND',
    qmode: 1,
  } as any;

  searchJobProfession = null;

  searchJobLocation = null;

  searchJobCompany = null;

  mapType = JobType.search;

  jobs:IJobs = {
    amt: undefined,
    firms: [],
    jobs: [],
  } as any;

  @Mutation
  UPDATE_MAP_JOBS(payload: any) {
    this.jobs = payload;
  }

  @Mutation
  UPDATE_MAP_TYPE(payload: any) {
    this.mapType = payload;
  }

  @Mutation
  SET_FILTER_DEFAULT() {
    Object.keys(this.jobMapFilter).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.jobMapFilter, key)) {
        this.jobMapFilter[key] = 0;
      }
    });
  }

  @Mutation
  SET_PARAMS_DEFAULT() {
    delete this.jobMapParams.id;
    this.jobMapParams.jobnews_type = 9;
  }

  @Mutation
  SET_SEARCH_DEFAULT() {
    this.jobMapSearch.radius = 0;
    this.jobMapSearch.jobnews_type = null;
    this.jobMapSearch.fulltext = 0;
    this.jobMapSearch.q = '';
    this.jobMapSearch.mode = 'AND';
    this.jobMapSearch.qmode = 1;
  }

  @Mutation
  SET_QUERY_SEARCH_DEFAULT() {
    this.searchJobProfession = null;
    this.searchJobLocation = null;
    this.searchJobCompany = null;
    this.jobMapSearch.profession_id = null;
    this.jobMapSearch.location_id = null;
    this.jobMapSearch.idcomp = null;
  }

  @Mutation
  SET_MAP_CLUSTER(payload: any) {
    const { obj, value } = payload;
    this.mapClusterParams[obj] = value;
  }

  @Mutation
  UPDATE_PROFESSION_MAP_ID(payload: any) {
    this.jobMapParams.id = payload;
  }

  @Mutation
  SET_SEARCH_JOB_MAP_PROFESSION(payload: any) {
    this.searchJobProfession = payload;
  }

  @Mutation
  SET_SEARCH_JOB_MAP_LOCATION(payload: any) {
    this.searchJobLocation = payload;
  }

  @Mutation
  SET_SEARCH_JOB_MAP_COMPANY(payload: any) {
    this.searchJobCompany = payload;
  }

  @Mutation
  private SET_NOTICE_MAP_REDIRECT(item: boolean) {
    this.noticeMapRedirect = item;
  }

  @Mutation
  private SET_VISIBLE_MAP(payload: any) {
    this.visibleMap = payload;
  }

  @Mutation
  private SET_MAP_CONFIG(payload: any) {
    this.mapConfig = payload;
  }

  @Mutation
  SET_SELECTED_REGION_JOBS(payload: any) {
    this.selectedRegionJobsIds = payload;
  }

  @Mutation
  SET_MAP_MARKER_IDS(array: any) {
    this.mapMarkersIds = array;
  }

  @Mutation
  UPDATE_PAGINATION(payload: any) {
    const { obj, value } = payload;
    this.jobMapPagination[obj] = value;
  }

  @Mutation
  UPDATE_MAP_JOBS_COUNT(payload: { filter: CountType, count: any }) {
    const { filter, count } = payload;
    this.employmentCount[filter] = count;
  }

  @Action
  public SetMapConfig(payload: any) {
    this.SET_MAP_CONFIG(payload);
  }

  @Action
  public SetSelectedRegionJobs(payload: any) {
    this.SET_SELECTED_REGION_JOBS(payload);
  }

  @Action
  ResetMapListFilters() {
    console.log('reset');
  }

  @Action
  SetVisibleMap(payload: boolean) {
    this.SET_VISIBLE_MAP(payload);
  }

  @Action
  public SetMapRedirect(item: boolean) {
    this.SET_NOTICE_MAP_REDIRECT(item);
  }

  @Action({ rawError: true })
  async GetMapMarkerJobs(payload: any) {
    const params = {
      ...this.jobMapSearch,
      ...this.jobMapParams,
      ...this.jobMapFilter,
      ...payload,
      skill_level1: this.jobMapFilter.skill_level2,
    };
    try {
      const request = await filterMarkerMap(params);

      this.SET_MAP_MARKER_IDS(request);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async GetMapPageJobs(payload: any) {
    const params = {
      ...payload.params,
      ...this.jobMapSearch,
      ...this.jobMapParams,
      ...this.jobMapFilter,
      ...this.jobMapPagination,
    };
    const request = await jobListMap(params, {
      job_ids: payload.ids,
    });
    this.UPDATE_MAP_JOBS(request);
  }

  @Action({ rawError: true })
  async GetMapJobsCount(payload: { filter: CountType, params: any }) {
    const { filter, params } = payload;
    const marget = {
      ...params,
      ...this.jobMapSearch,
      state_id: this.jobMapFilter.state_id,
      cluster_id: this.jobMapFilter.cluster_id,
    };
    const request = await jobListItemsSearch(marget);
    this.UPDATE_MAP_JOBS_COUNT({ filter, count: request.amt || 0 });
  }

  @Action
  async SetSearchJobMapProfession(payload: any) {
    this.SET_SEARCH_JOB_MAP_PROFESSION(payload);
  }

  @Action
  async SetSearchJobMapLocation(payload: any) {
    this.SET_SEARCH_JOB_MAP_LOCATION(payload);
  }

  @Action
  async SetSearchJobMapCompany(payload: any) {
    this.SET_SEARCH_JOB_MAP_COMPANY(payload);
  }

  @Mutation
  UPDATE_SEARCH_MAP(payload: any) {
    const { obj, value } = payload;
    this.jobMapSearch[obj] = value;
  }

  @Mutation
  UPDATE_FILTER_MAP(payload: any) {
    const { obj, value } = payload;
    this.jobMapFilter[obj] = value;
  }

  @Action
  async SetSearchMap(payload: any) {
    this.UPDATE_SEARCH_MAP(payload);
  }

  @Action
  async SetFilterMap(payload: any) {
    this.UPDATE_FILTER_MAP(payload);
  }

  @Action
  async SetProfessionMapId(payload: any) {
    this.UPDATE_PROFESSION_MAP_ID(payload);
  }

  @Action
  async SetParamDefault() {
    this.SET_FILTER_DEFAULT();
    this.SET_PARAMS_DEFAULT();
    this.SET_SEARCH_DEFAULT();
    this.SET_QUERY_SEARCH_DEFAULT();
  }

  @Action
  async SetMapType(payload: any) {
    this.UPDATE_MAP_TYPE(payload);
  }

  @Action
  async SetMapPagination(payload: any) {
    this.UPDATE_PAGINATION(payload);
  }
}

export const MapModule = getModule(Map);
