import { Component, Vue } from 'vue-property-decorator';

import { IRegistrationField, RegistrationField, TypeField } from '@/types/Login';
import { AppModule } from '@/store/modules/app';
import { getCaptchaVerify } from '@/api/Captcha';

@Component
export default class extends Vue {
  typeField = TypeField;

  regField = RegistrationField;

  captcha = '';

  filename = ''

  isRecaptchaError = false;

  isRecaptchaReload = false;

  get applicantRegistrationFields(): IRegistrationField[] {
    return AppModule.setting?.login_tabs?.applicant_registration_fields || [];
  }

  get companyRegistrationFields() {
    return AppModule.setting?.login_tabs?.company_registration_fields || [];
  }

  isField(type: TypeField, name: RegistrationField, fields: IRegistrationField[]) {
    if (fields?.length) {
      const field = fields.find((x: IRegistrationField) => x.name === name);
      return field ? field[type] : false;
    }
    return false;
  }

  isApplicantField(type: TypeField, name: RegistrationField) {
    return this.isField(type, name, this.applicantRegistrationFields);
  }

  isCompanyField(type: TypeField, name: RegistrationField) {
    return this.isField(type, name, this.companyRegistrationFields);
  }

  getApplicantField(name: RegistrationField): IRegistrationField {
    return this.applicantRegistrationFields?.find((v: IRegistrationField) => v.name === name) || {} as IRegistrationField;
  }

  isFieldExist(fields: IRegistrationField[], name: RegistrationField): boolean {
    return !!fields.find((v: IRegistrationField) => v.name === name);
  }

  isApplicantFieldExist(name: RegistrationField): boolean {
    return this.isFieldExist(this.applicantRegistrationFields, name);
  }

  updateCaptchaToken(payload: string) {
    this.filename = payload;
  }

  async captchaVerify() {
    const params = {
      text: this.captcha,
      token: this.filename,
    };
    try {
      await getCaptchaVerify(params);
      this.isRecaptchaError = false;
    } catch (error: any) {
      if (error.response.status === 404) {
        this.isRecaptchaReload = true;
        this.$nextTick(() => {
          this.isRecaptchaReload = false;
        });
      }
      console.error(error);
      this.isRecaptchaError = true;
    }
  }
}
