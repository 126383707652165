import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import {
  getGenerateToken,
  getUserInfoApplicant,
  getUserInfoCompany,
  getUserInfoConsultant,
  loginApplicant,
  loginCompany,
  loginConsultant,
  loginRefresh,
  refreshSession,
  registerApplicant,
  registerCompany,
} from '@/api/Auth';
import { getCookiesSite, setCookiesSite } from '@/plugins/cookies';
import { ILoginRequest, IRegister, ISignin } from '@/types/Login';
import {
  IGroupToken, IServerToken, ISetToken, UserType,
} from '@/types/ServerToken';
import store from '@/store';
import { NotebookModule } from './notebook';

export interface IUserState {
  serverToken: IServerToken
  accessTokenApplicant: string
  refreshTokenApplicant: string
  expiresTokenApplicant: string | number
  accessTokenConsultant: string
  refreshTokenConsultant: string
  expiresTokenConsultant: string | number
  accessTokenCompany: string
  refreshTokenCompany: string
  expiresTokenCompany: string | number
  userInfoApplicant: any
  userInfoConsultant: any
  userInfoCompany: any
  isRedirectToCabinet: boolean
  openAgentPageOnCabinet: boolean
}

const logoutChat = () => {
  if ((window as any).main && (window as any).main.chat && !(window as any).main.chat.closed) {
    (window as any).main.chat.close_handler();
  }
};

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  serverToken = JSON.parse(localStorage.getItem('server_token') as string) || {};

  accessTokenApplicant = localStorage.getItem('access_token_applicant') || '';

  refreshTokenApplicant = localStorage.getItem('refresh_token_applicant') || '';

  expiresTokenApplicant = localStorage.getItem('expires_token_applicant') || 0;

  accessTokenConsultant = localStorage.getItem('access_token_consultant') || '';

  refreshTokenConsultant = localStorage.getItem('refresh_token_consultant') || '';

  expiresTokenConsultant = localStorage.getItem('expires_token_consultant') || 0;

  accessTokenCompany = localStorage.getItem('access_token_company') || '';

  refreshTokenCompany = localStorage.getItem('refresh_token_company') || '';

  expiresTokenCompany = localStorage.getItem('expires_token_company') || 0;

  userInfoApplicant = null;

  userInfoConsultant = null;

  userInfoCompany = null;

  accessTokenGenerate = '';

  cookiesAccess = getCookiesSite() || '';

  isRedirectToCabinet = true;

  loginAlpha = localStorage.getItem('login_alpha') || '';

  openAgentPageOnCabinet = false;

  get isUserAuth(): boolean {
    return !!this.accessTokenApplicant || !!this.accessTokenConsultant || !!this.accessTokenCompany;
  }

  @Mutation
  SET_OPEN_AGENT_PAGE(payload: boolean) {
    this.openAgentPageOnCabinet = payload;
  }

  @Mutation
  IS_REDIRECT_TO_CABINET(payload: boolean) {
    this.isRedirectToCabinet = payload;
  }

  @Mutation
  private SET_SERVER_TOKEN(payload: { sid: string, request: ILoginRequest, type: number }) {
    const { sid, request, type } = payload;
    let token: IGroupToken = {};
    if (type === UserType.applicant) {
      token = {
        applicant: {
          access_token: request.access_token,
          refresh_token: request.refresh_token,
          exp: request.expires_in,
        },
      };
    }
    if (type === UserType.consultant) {
      token = {
        consultant: {
          access_token: request.access_token,
          refresh_token: request.refresh_token,
          exp: request.expires_in,
        },
      };
    }
    if (type === UserType.company) {
      token = {
        company: {
          access_token: request.access_token,
          refresh_token: request.refresh_token,
          exp: request.expires_in,
        },
      };
    }
    const setGroup = {
      [sid]: { ...token },
    };
    this.serverToken = Object.assign(this.serverToken, setGroup);
    localStorage.setItem('server_token', JSON.stringify(this.serverToken));
  }

  @Mutation
  private REMOVE_SERVER_TOKEN(sid: string) {
    delete this.serverToken[sid];
    localStorage.setItem('server_token', JSON.stringify(this.serverToken));
  }

  @Mutation
  private SET_LOGIN_ALPHA(name: string) {
    this.loginAlpha = name;
    localStorage.setItem('login_alpha', name);
  }

  @Mutation
  private SET_REMOVE_ALPHA() {
    this.loginAlpha = '';
    localStorage.removeItem('login_alpha');
  }

  @Mutation
  private SET_TOKEN_APPLICANT(payload: { token: string, refresh: string, exp: string }) {
    const { token, refresh, exp } = payload;
    this.accessTokenApplicant = token;
    this.refreshTokenApplicant = refresh;
    this.expiresTokenApplicant = exp;
    localStorage.setItem('access_token_applicant', token);
    localStorage.setItem('refresh_token_applicant', refresh);
    localStorage.setItem('expires_token_applicant', exp);
  }

  @Mutation
  private SET_TOKEN_GENERATE(payload: { token: string }) {
    const { token } = payload;
    this.accessTokenGenerate = token;
  }

  @Mutation
  private SET_TOKEN_CONSULTANT(payload: { token: string, refresh: string, exp: string }) {
    const { token, refresh, exp } = payload;
    this.accessTokenConsultant = token;
    this.refreshTokenConsultant = refresh;
    this.expiresTokenConsultant = exp;
    localStorage.setItem('access_token_consultant', token);
    localStorage.setItem('refresh_token_consultant', refresh);
    localStorage.setItem('expires_token_consultant', exp);
  }

  @Mutation
  private SET_TOKEN_COMPANY(payload: { token: string, refresh: string, exp: string }) {
    const { token, refresh, exp } = payload;
    this.accessTokenCompany = token;
    this.refreshTokenCompany = refresh;
    this.expiresTokenCompany = exp;
    localStorage.setItem('access_token_company', token);
    localStorage.setItem('refresh_token_company', refresh);
    localStorage.setItem('expires_token_company', exp);
  }
  // ..................

  @Mutation
  private REMOVE_TOKEN_APPLICANT() {
    this.accessTokenApplicant = '';
    this.refreshTokenApplicant = '';
    this.expiresTokenApplicant = '';
    localStorage.removeItem('access_token_applicant');
    localStorage.removeItem('refresh_token_applicant');
    localStorage.removeItem('expires_token_applicant');
  }

  @Mutation
  private REMOVE_TOKEN_CONSULTANT() {
    this.accessTokenConsultant = '';
    this.refreshTokenConsultant = '';
    this.expiresTokenConsultant = '';
    localStorage.removeItem('access_token_consultant');
    localStorage.removeItem('refresh_token_consultant');
    localStorage.removeItem('expires_token_consultant');
  }

  @Mutation
  private REMOVE_TOKEN_COMPANY() {
    this.accessTokenCompany = '';
    this.refreshTokenCompany = '';
    this.expiresTokenCompany = '';
    localStorage.removeItem('access_token_company');
    localStorage.removeItem('refresh_token_company');
    localStorage.removeItem('expires_token_company');
  }
  // ..................

  @Mutation
  private SET_COOKIE(cookie: string) {
    this.cookiesAccess = cookie;
    setCookiesSite(cookie);
  }

  @Mutation
  private SET_USER_APPLICANT(data: any) {
    this.userInfoApplicant = data;
  }

  @Mutation
  private SET_USER_CONSULTANT(data: any) {
    this.userInfoConsultant = data;
  }

  @Mutation
  private SET_USER_COMPANY(data: any) {
    this.userInfoCompany = data;
  }

  @Action
  SetCookie(cookie: string) {
    this.SET_COOKIE(cookie);
  }

  @Action({ rawError: true })
  async LoginApplicant(userInfo: ISignin): Promise<void> {
    try {
      const { username, password, sid } = userInfo;
      const request = await loginApplicant({ username, password, sid });
      this.SET_TOKEN_APPLICANT({
        token: request.access_token,
        refresh: request.refresh_token,
        exp: request.expires_in,
      });
      this.SET_SERVER_TOKEN({ sid, request, type: UserType.applicant });
      // await this.GenerateToken();
      await this.GetUserInfoApplicant();
      // remove last user's notebook list
      NotebookModule.DELETE_ALL_NOTEBOOK_NOTES();
      await NotebookModule.getApplicantNotebookData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async GenerateToken(): Promise<void> {
    try {
      const request = await getGenerateToken();
      this.SET_TOKEN_GENERATE({
        token: request.token,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async LoginConsultant(userInfo: ISignin): Promise<void> {
    try {
      const { username, password, sid } = userInfo;
      const request = await loginConsultant({ username, password, sid });
      this.SET_TOKEN_CONSULTANT({
        token: request.access_token,
        refresh: request.refresh_token,
        exp: request.expires_in,
      });
      this.SET_SERVER_TOKEN({ sid, request, type: UserType.consultant });
      await this.GetUserInfoConsultant();
      await NotebookModule.getConsultantNotebookData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async LoginCompany(userInfo: ISignin): Promise<void> {
    try {
      const { username, password, sid } = userInfo;
      const request = await loginCompany({ username, password, sid });
      this.SET_TOKEN_COMPANY({
        token: request.access_token,
        refresh: request.refresh_token,
        exp: request.expires_in,
      });
      this.SET_SERVER_TOKEN({ sid, request, type: UserType.company });
      await this.GetUserInfoCompany();
      await NotebookModule.getCompanyNotebookData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async RegisterApplicant(registerInfo: IRegister): Promise<void> {
    try {
      return await registerApplicant(registerInfo);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async RegisterCompany(registerInfo: IRegister): Promise<void> {
    try {
      return await registerCompany(registerInfo);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async RefreshTokenApplicant(sid: string) {
    if (this.refreshTokenApplicant === '') {
      throw Error('refreshTokenApplicant: token is undefined!');
    }
    try {
      const request = await loginRefresh(this.refreshTokenApplicant);
      this.SET_TOKEN_APPLICANT({
        token: request.access_token,
        refresh: request.refresh_token,
        exp: request.expires_in,
      });
      this.SET_SERVER_TOKEN({ sid, request, type: UserType.applicant });
      await this.GetUserInfoApplicant();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  RefreshTokenConsultant(sid: string) {
    if (this.refreshTokenConsultant === '') {
      throw Error('refreshTokenConsultant: token is undefined!');
    }
    this.LogOutConsultant(sid);
  }

  @Action({ rawError: true })
  RefreshTokenCompany(sid: string) {
    if (this.refreshTokenCompany === '') {
      throw Error('refreshTokenCompany: token is undefined!');
    }
    this.LogOutCompany(sid);
  }

  @Action({ rawError: true })
  async GetUserInfoApplicant(): Promise<void> {
    if (this.accessTokenApplicant === '') {
      throw Error('GetUserInfoApplicant: token is undefined!');
    }
    try {
      const request = await getUserInfoApplicant();
      this.SET_USER_APPLICANT(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async GetUserInfoConsultant(): Promise<void> {
    if (this.accessTokenConsultant === '') {
      throw Error('GetUserInfoConsultant: token is undefined!');
    }
    try {
      const request = await getUserInfoConsultant();
      this.SET_USER_CONSULTANT(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async GetUserInfoCompany(): Promise<void> {
    if (this.accessTokenCompany === '') {
      throw Error('GetUserInfoConsultant: token is undefined!');
    }
    try {
      const request = await getUserInfoCompany();
      this.SET_USER_COMPANY(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  public LogOutApplicant(sid: string) {
    this.REMOVE_SERVER_TOKEN(sid);
    this.REMOVE_TOKEN_APPLICANT();
    this.SET_USER_APPLICANT(null);
    logoutChat();
    refreshSession();
  }

  @Action({ rawError: true })
  public LogOutConsultant(sid: string) {
    this.REMOVE_SERVER_TOKEN(sid);
    this.REMOVE_TOKEN_CONSULTANT();
    this.SET_USER_CONSULTANT(null);
    logoutChat();
    refreshSession();
  }

  @Action
  public LogOutCompany(sid: string) {
    this.REMOVE_SERVER_TOKEN(sid);
    this.REMOVE_TOKEN_COMPANY();
    this.SET_USER_COMPANY(null);
    this.SET_REMOVE_ALPHA();
    logoutChat();
    refreshSession();
  }

  @Action
  public SetAlphaLogin(name: string) {
    this.SET_LOGIN_ALPHA(name);
  }

  @Action
  public RemoveAlphaLogin() {
    this.SET_REMOVE_ALPHA();
  }

  @Action
  UpdateTokenParamsApplicant(payload: { sid: string, request: ILoginRequest }) {
    const { request, sid } = payload;
    this.SET_SERVER_TOKEN({ sid, request, type: UserType.applicant });
    this.SET_TOKEN_APPLICANT({
      token: request.access_token,
      refresh: request.refresh_token,
      exp: request.expires_in,
    });
  }

  @Action
  UpdateServerTokenParams(payload: { params: ISetToken, type: number }) {
    const { params, type } = payload;
    if (UserType.applicant === type) {
      this.SET_TOKEN_APPLICANT({
        token: params.access_token,
        refresh: params.refresh_token,
        exp: params.exp,
      });
    }
    if (UserType.consultant === type) {
      this.SET_TOKEN_CONSULTANT({
        token: params.access_token,
        refresh: params.refresh_token,
        exp: params.exp,
      });
    }
    if (UserType.company === type) {
      this.SET_TOKEN_COMPANY({
        token: params.access_token,
        refresh: params.refresh_token,
        exp: params.exp,
      });
    }
  }

  @Action
  DefaultTokens() {
    this.SET_TOKEN_APPLICANT({
      token: '',
      refresh: '',
      exp: '',
    });
    this.REMOVE_TOKEN_APPLICANT();
    // Consultant
    this.SET_TOKEN_CONSULTANT({
      token: '',
      refresh: '',
      exp: '',
    });
    this.REMOVE_TOKEN_CONSULTANT();
    // Company
    this.SET_TOKEN_COMPANY({
      token: '',
      refresh: '',
      exp: '',
    });
    this.REMOVE_TOKEN_COMPANY();
  }
}
export const UserModule = getModule(User);
