<template>
  <div id="app" :dir="isRtl">
    <router-view />
    <auth-sidebar />
    <notebook-sidebar />
    <worker-sidebar />
    <worker-sidebar-new />
    <company-sidebar />
    <jobs-sidebar />
    <cookie-banner />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import InformationNavigation from '@/components/Header/InformationNavigation.vue';
import PagesNavigation from '@/components/Header/PagesNavigation.vue';
import AuthSidebar from '@/components/Authorization/AuthSidebar.vue';
import NotebookSidebar from '@/components/Notebook/NotebookSidebar.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import WorkerSidebar from '@/components/Workers/WorkerSidebar.vue';
import WorkerSidebarNew from '@/components/Workers/WorkerSidebarNew.vue';
import JobsSidebar from '@/components/Home/JobsSidebar.vue';
import CompanySidebar from '@/components/Company/CompanySidebar.vue';
import CookieBanner from '@/components/CookieBanner.vue';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import TemplatesMixin from '@/mixins/TemplatesMixin';
import { NotebookModule } from './store/modules/notebook';

declare const Main: any;

@Component({
  metaInfo(this: any): any {
    return {
      title: this.pageTitle,
    };
  },
  components: {
    InformationNavigation,
    PagesNavigation,
    FooterComponent,
    AuthSidebar,
    NotebookSidebar,
    WorkerSidebar,
    WorkerSidebarNew,
    JobsSidebar,
    CompanySidebar,
    CookieBanner,
  },
})
export default class App extends mixins(TemplatesMixin) {
  get languageSid() {
    return AppModule.languageSid;
  }

  get isRtl() {
    return AppModule.rtl;
  }

  get pageTitle() {
    return AppModule.setting.html_title;
  }

  @Watch('$route', { deep: true })
  handler() {
    document.body.classList.remove('overflow-hidden');
  }

  directApply() {
    window.addEventListener('message', (event) => {
      if (event.data.event === 'direct_apply' && event.data.jobId) {
        if (window.dataLayer) {
          window.dataLayer.push(event.data);
        } else {
          console.log('no dataLayer');
        }
      }
    });
  }

  createFontsTemplate() {
    if (this.sid === 'jig') {
      this.jigCreateFontsUrl();
    } else if ([
      'talentpool',
      'edunet',
      'wconat',
      'ncph',
      't4a'].includes(this.sid)) {
      this.talentpoolCreateFontsUrl();
    }
  }

  startEvent() {
    (window as any).support_chat = true;
    (window as any).auth_by_token = true;
    Main.prototype.absoluteLinks = false;
    (window as any).main = new Main(this.sid);
    (window as any).main.load_module('chat');
  }

  async created() {
    const htmlElement = document.documentElement;
    htmlElement.setAttribute('sid', this.sid);
    if (this.sid === 'talentpool' || this.sid === 'jig' || this.sid === 'aba' || this.sid === 'abatest') {
      htmlElement.setAttribute('theme', this.sid);
    } else {
      htmlElement.setAttribute('theme', 'default');
    }

    this.createIconUrl();

    this.createFontsTemplate();

    const loadTranslation = AppModule.getTranslation();
    await Promise.all([loadTranslation]);
    await AppModule.getSettingPortal({ sid: this.languageSid });
    await AppModule.getSetting({ sid: this.languageSid });
    await AppModule.getJobCountPortal({ sid: this.languageSid });

    if (UserModule.accessTokenApplicant) {
      if (!UserModule.userInfoApplicant) {
        await UserModule.GetUserInfoApplicant();
        await NotebookModule.getApplicantNotebookData();
      }
    }
    if (UserModule.accessTokenConsultant) {
      if (!UserModule.userInfoConsultant) {
        await UserModule.GetUserInfoConsultant();
        await NotebookModule.getConsultantNotebookData();
      }
    }
    if (UserModule.accessTokenCompany) {
      if (!UserModule.userInfoCompany) {
        await UserModule.GetUserInfoCompany();
        await NotebookModule.getCompanyNotebookData();
      }
    }

    this.directApply();

    this.startEvent();
  }
}
</script>
