<template>
  <div v-if="isSuccessRegister">
    <h5>{{ translations[13898] }}</h5>
    <p v-if="getPasswordRegister">
      {{ translations[10809] }}
    </p>
    <p v-else>
      {{ translations[13899] }}
    </p>
    <p>
      {{ translations[13932] }}
      <a class="fw-bolder" href="#" @click.prevent="reloadTab">
        {{ translations[13933] }}
      </a>
    </p>
  </div>
  <b-form ref="registerForm" v-else autocomplete="new-password">
    <b-alert :show="isErrorsRegister" variant="danger">
      <span v-if="isUsernameExist">{{ translations[232] }}</span>
      <span v-if="isEmailExist">{{ translations[10870] }}</span>
    </b-alert>
    <b-alert :show="isRecaptchaError" variant="danger"> Please confirm the captcha. </b-alert>
    <b-alert :show="isRegisterPasswordError" variant="danger">
      {{ translations[240] }}
    </b-alert>
    <!--    new field 30.07.2024-->
    <div class="mb-2">
      <div>
        <h5 class="font-weight-normal">{{ translations[1315] }}</h5>
        <b-form-group v-if="isApplicantFieldExist(regField.username)" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model="register.username"
            type="text"
            autocomplete="new-password"
            :placeholder="translations[11001]"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="isApplicantFieldExist(regField.email)" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model="register.email"
            type="email"
            autocomplete="new-password"
            :placeholder="translations[5549]"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.firstname)" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model="register.firstname"
            type="text"
            :placeholder="translations[234]"
            :required="isApplicantField(typeField.mandatory, regField.firstname)"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.lastname)" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model="register.lastname"
            type="text"
            :placeholder="translations[235]"
            :required="isApplicantField(typeField.mandatory, regField.lastname)"
          ></b-form-input>
        </b-form-group>
        <b-input-group v-if="isShowPasswordRegister" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model.trim="register.password"
            :type="passwordFieldType"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            :placeholder="translations[10948]"
            :title="translations[2521]"
            autocomplete="new-password"
            minlength="8"
            required
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="custom-light" @click="togglePasswordVisibility">
              <b-icon :icon="passwordFieldIcon"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group v-if="isShowPasswordRegister" class="mb-1">
          <b-form-input
            size="lg"
            class="border-0"
            v-model.trim="register.password_repeat"
            :type="passwordRepeatFieldType"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            :placeholder="translations[236]"
            :title="translations[2521]"
            autocomplete="new-password"
            minlength="8"
            required
          />
          <b-input-group-append>
            <b-button variant="custom-light" @click="togglePasswordRepeatVisibility">
              <b-icon :icon="passwordRepeatFieldIcon"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.birthdate)" class="position-relative mb-1">
          <b-input-group>
            <b-form-datepicker
              v-model="birthdateValue"
              button-only
              right
              :locale="lang"
              :max="maxDate"
              :label-no-date-selected="translations[1724]"
              :label-next-month="translations[3407]"
              :label-prev-month="translations[3406]"
              :label-next-year="translations[10815]"
              :label-prev-year="translations[10814]"
              :label-current-month="translations[10813]"
              :label-help="translations[10812]"
              :label-prev-decade="translations[15220]"
              :label-next-decade="translations[15221]"
              show-decade-nav
              size="lg"
              class="border-0"
              :required="isApplicantField(typeField.mandatory, regField.birthdate)"
              @context="onBirthdateContext"
            />
            <b-form-input
              v-model="birthdateValue"
              v-mask="'####-##-##'"
              type="text"
              :placeholder="translations[15222]"
              autocomplete="off"
              size="lg"
              class="border-0"
            />
          </b-input-group>
          <!-- using for required control -->
          <b-form-input
            v-if="isApplicantField(typeField.mandatory, regField.birthdate)"
            v-model="register.birthdate"
            required
            tabindex="-1"
            class="position-absolute top-0 -z-1"
          />
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.salutation)" class="mb-1">
          <b-form-select
            v-model="register.salutation"
            :options="getApplicantField(regField.salutation).options"
            text-field="name"
            size="lg"
            class="border-0"
            :placeholder="translations[10823]"
            :required="isApplicantField(typeField.mandatory, regField.salutation)"
          />
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.country)" class="mb-1 mt-3">
          <v-select
            label="name"
            v-model="registerCountry"
            :options="getApplicantField(regField.country).options"
            :placeholder="translations[15035]"
            class="form-control form-control-lg border-0 default"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="isApplicantField(typeField.mandatory, regField.country) && !registerCountry"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.citizenship_and_residence_status)" class="mb-1">
          <v-select
            label="name"
            v-model="registerCitizenship"
            :options="getApplicantField(regField.citizenship_and_residence_status).options"
            :placeholder="translations[15036]"
            class="form-control form-control-lg border-0 default"
            multiple
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="isApplicantField(typeField.mandatory, regField.citizenship_and_residence_status) && (registerCitizenship.length === 0)"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </b-form-group>
      </div>
      <b-form-group v-if="isGroupVisible(GROUP_GOAL_ID)" v-slot="{ ariaDescribedby }" class="fs-6 mb-1 mt-3">
        <h5 class="font-weight-normal">{{ translations[15195] }}</h5>
        <template v-for="item in getSpecificGroupOptions(GROUP_GOAL_ID)">
          <b-form-checkbox
            v-if="isApplicantField(typeField.visible, regField[item.name])"
            :key="item.name"
            v-model="register[item.name]"
            :aria-describedby="ariaDescribedby"
            :required="getRequiredStateForSpecificGroup(GROUP_GOAL_ID)"
            name="goal-checkbox"
            switch
            :value="1"
            :unchecked-value="0"
          >{{ translations[item.txt || ''] }}</b-form-checkbox>
        </template>
      </b-form-group>
      <b-form-group v-if="isGroupVisible(GROUP_STUDY_STATUS_ID)" v-slot="{ ariaDescribedby }" class="fs-6 mb-1 mt-3">
        <h5 class="font-weight-normal">{{ translations[15202] }}</h5>
        <template v-for="item in getSpecificGroupOptions(GROUP_STUDY_STATUS_ID)">
          <b-form-checkbox
            v-if="isApplicantField(typeField.visible, regField[item.name])"
            :key="item.name"
            v-model="register[item.name]"
            :aria-describedby="ariaDescribedby"
            :required="getRequiredStateForSpecificGroup(GROUP_STUDY_STATUS_ID)"
            name="study-status-checkbox"
            switch
            :value="1"
            :unchecked-value="0"
          >{{ translations[item.txt || ''] }}</b-form-checkbox>
        </template>
      </b-form-group>
      <b-form-group v-if="isApplicantField(typeField.visible, regField.status)" v-slot="{ ariaDescribedby }" class="fs-6 mb-1 mt-3">
        <h5 class="font-weight-normal">{{ translations[15208] }}</h5>
        <b-form-radio
          v-for="radioItem in getApplicantField(regField.status).options"
          :key="radioItem.value"
          v-model="register.status"
          :aria-describedby="ariaDescribedby"
          name="radios-status"
          :value="radioItem.value"
          :required="isApplicantField(typeField.mandatory, regField.status)"
        >
          <span v-b-tooltip.hover :title="radioItem.tooltip"> {{ radioItem.name }}</span></b-form-radio
        >
      </b-form-group>
      <div v-if="isGroupVisible(GROUP_SELECTED_CONTACT)" class="mb-1 mt-3">
        <h5 class="font-weight-normal">{{ translations[15219] }}</h5>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.consultant)" v-slot="{ ariaDescribedby }">
          <h6 class="font-weight-normal mb-1">{{ translations[15200] }}</h6>
          <b-form-select
            v-model="register.consultant"
            :options="getApplicantField(regField.consultant).options"
            text-field="name"
            size="lg"
            class="border-0"
            :aria-describedby="ariaDescribedby"
            :placeholder="translations[1505]"
            :required="isApplicantField(typeField.mandatory, regField.consultant)"
          />
        </b-form-group>
        <b-form-group v-if="isApplicantField(typeField.visible, regField.referral_crm_company_id)" v-slot="{ ariaDescribedby }">
          <h6 class="font-weight-normal mb-1">{{ translations[15201] }}</h6>
          <b-form-select
            v-model="register.referral_crm_company_id"
            :options="getApplicantField(regField.referral_crm_company_id).options"
            text-field="name"
            size="lg"
            class="border-0"
            :aria-describedby="ariaDescribedby"
            :placeholder="translations[1505]"
            :required="isApplicantField(typeField.mandatory, regField.referral_crm_company_id)"
          />
        </b-form-group>
      </div>
    </div>
    <!--    new field 30.07.2024-->
    <div>
      <h5 class="font-weight-normal">{{ translations[15218] }}</h5>
      <b-form-group v-if="isApplicantField(typeField.visible, regField.consent_registration_fee)" class="mb-1">
        <b-form-checkbox
          :class="[{ 'is-invalid': isConsentError }]"
          aria-describedby="consent-live-feedback"
          v-model="register.consent_registration_fee"
          name="check-button"
          switch
        >
          <p class="fs-6 mb-0" v-html="translations[15044]"></p>
          <p class="fs-6 mb-0">
            <span v-html="getApplicantField(regField.consent_registration_fee).value" />
          </p>
        </b-form-checkbox>
        <b-form-invalid-feedback id="consent-live-feedback">
          {{ translations[15057] }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="mb-2">
        <b-form-checkbox
          :class="[{ 'is-invalid': isPrivacyError }]"
          v-model="isRegisterPrivacy"
          name="check-button"
          aria-describedby="privacy-live-feedback"
          switch
        >
          <p class="fs-6 mb-0" ref="content" v-html="translations[15008]"></p>
        </b-form-checkbox>
        <b-form-invalid-feedback id="privacy-live-feedback">
          {{ translations[15058] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <b-form-group v-if="isApplicantField(typeField.visible, regField.captcha) && !isRecaptchaReload" class="mb-1 mt-2">
      <Captcha v-model="captcha" @change="updateCaptchaToken" />
    </b-form-group>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import RegistrationFieldsMixin from '@/mixins/RegistrationFieldsMixin';
import { RegistrationField, TypeField } from '@/types/Login';
import Captcha from '@/components/Widget/Captcha.vue';
import { getCaptchaVerify } from '@/api/Captcha';

const GROUP_GOAL_ID = 2;
const GROUP_STUDY_STATUS_ID = 3;
const GROUP_SELECTED_CONTACT = 5;

@Component({
  components: {
    Captcha,
  },
})
export default class RegisterApplicantForm extends mixins(RegistrationFieldsMixin) {
  GROUP_GOAL_ID = GROUP_GOAL_ID;

  GROUP_STUDY_STATUS_ID = GROUP_STUDY_STATUS_ID;

  GROUP_SELECTED_CONTACT = GROUP_SELECTED_CONTACT;

  isPasswordVisible = false;

  isPasswordRepeatVisible = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  get lang() {
    return AppModule.language;
  }

  get isErrorsRegister() {
    return this.isUsernameExist || this.isEmailExist;
  }

  get getPasswordRegister() {
    return AppModule.setting?.registration_with_password;
  }

  get passwordFieldType() {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  get passwordFieldIcon() {
    return this.isPasswordVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  get passwordRepeatFieldType() {
    return this.isPasswordRepeatVisible ? 'text' : 'password';
  }

  get passwordRepeatFieldIcon() {
    return this.isPasswordRepeatVisible ? 'eye-slash' : 'eye';
  }

  isGroupVisible(groupId: number) {
    return this.applicantRegistrationFields.filter((el: any) => el.group_id === groupId).some((el: any) => el[this.typeField.visible]);
  }

  getSpecificGroupOptions(groupId: number) {
    return this.applicantRegistrationFields.filter((el: any) => el.group_id === groupId && el[this.typeField.visible]);
  }

  getRequiredStateForSpecificGroup(groupId: number) {
    return !this.getSpecificGroupOptions(groupId).some((item) => !!this.register[item.name]);
  }

  togglePasswordRepeatVisibility() {
    this.isPasswordRepeatVisible = !this.isPasswordRepeatVisible;
  }

  isSuccessRegister = false;

  register = {
    username: '',
    password: '',
    password_repeat: '',
    email: '',
    sid: this.sid,
    lang: this.lang,
    firstname: '',
    lastname: '',
    // new field
    birthdate: '',
    country: '',
    citizenship_and_residence_status: '',
    consent_registration_fee: false,
    status: null,
    salutation: null,
    work: 0,
    apprenticeship: 0,
    study: 0,
    employed: 0,
    school_graduated: 0,
    school: 0,
    completed_studies: 0,
    studying: 0,
    consultant: null,
    referral_crm_company_id: null,
  } as any;

  isRegisterPrivacy = false;

  isRegisterPasswordError = false;

  isPrivacyError = false;

  isConsentError = false;

  get isErrorsRegisterValidate() {
    return this.isPrivacyError || this.isConsentError || this.isRegisterPasswordError;
  }

  birthdateValue = '';

  onBirthdateContext(ctx: any) {
    if (+this.maxDate < +new Date(ctx.selectedYMD)) return;
    this.register.birthdate = ctx.selectedYMD;
  }

  get maxDate() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return new Date(today);
  }

  @Watch('isRegisterPrivacy')
  handler(val: boolean) {
    if (val) this.isPrivacyError = false;
  }

  @Watch('register.consent_registration_fee')
  handlerConsent(val: boolean) {
    if (val) this.isConsentError = false;
  }

  @Watch('register.password')
  handlerPassword(val: boolean) {
    if (val) this.isRegisterPasswordError = false;
  }

  @Watch('register.password_repeat')
  handlerPasswordRepeat(val: boolean) {
    if (val) this.isRegisterPasswordError = false;
  }

  isEmailExist = false;

  isUsernameExist = false;

  registerCountry: { value?: string, name?: string } | string = '';

  registerCitizenship: { value?: string, name?: string }[] = [];

  get isShowPasswordRegister() {
    return AppModule.setting?.registration_with_password;
  }

  reloadTab() {
    this.$bvModal.hide('register-modal');
    this.$bvModal.show('login-modal');
  }

  resetErrors() {
    this.isEmailExist = false;
    this.isUsernameExist = false;
  }

  async onLogin() {
    try {
      const login = {
        username: this.register.username,
        password: this.register.password,
        sid: this.sid,
      };
      await UserModule.LoginApplicant(login);
      await this.$router.push({
        name: 'Cabinet',
        query: {
          lang: this.lang,
          ...(UserModule.openAgentPageOnCabinet && { agent: '1' }),
        },
      });
    } catch (error: any) {
      console.error(error);
    }
  }

  get getOptionalInvisibleFields() {
    return this.applicantRegistrationFields.filter((el) => Object.prototype.hasOwnProperty.call(el, TypeField.visible) && !el.visible).map((el) => el.name);
  }

  async onRegistration() {
    const form = this.$refs.registerForm as HTMLFormElement;
    if (!form.reportValidity()) {
      return;
    }

    this.resetErrors();

    if (!this.isRegisterPrivacy) {
      this.isPrivacyError = true;
    }

    if (this.isApplicantField(TypeField.visible, RegistrationField.consent_registration_fee) && !this.register.consent_registration_fee) {
      this.isConsentError = true;
    }

    if (!this.isShowPasswordRegister) {
      delete this.register.password;
      delete this.register.password_repeat;
    } else if (this.register.password !== this.register.password_repeat) {
      this.isRegisterPasswordError = true;
    }

    if (this.isErrorsRegisterValidate) {
      return;
    }

    if (this.isApplicantField(TypeField.visible, RegistrationField.captcha)) {
      await this.captchaVerify();
      if (this.isRecaptchaError) {
        return;
      }
    }

    try {
      // TODO get this list from exist fields
      // const applicantOptionalFields = [
      //   RegistrationField.firstname,
      //   RegistrationField.lastname,
      //   RegistrationField.country,
      //   RegistrationField.citizenship_and_residence_status,
      //   RegistrationField.consent_registration_fee,
      //   RegistrationField.status,
      //   RegistrationField.salutation,
      //   RegistrationField.work,
      //   RegistrationField.apprenticeship,
      //   RegistrationField.study,
      // ];

      // const applicantOptionalFields2 =

      if (this.isApplicantField(TypeField.visible, RegistrationField.country)) {
        this.register.country = (this.registerCountry as { value?: string }).value || '';
      }

      if (this.isApplicantField(TypeField.visible, RegistrationField.citizenship_and_residence_status)) {
        this.register.citizenship_and_residence_status = (this.registerCitizenship as { value?: string, name?: string }[]).map((x) => x.value) || [];
      }

      // applicantOptionalFields.forEach((field) => {
      //   if (!this.isApplicantField(TypeField.visible, field)) {
      //     delete this.register[field];
      //   }
      // });
      this.getOptionalInvisibleFields.forEach((field) => {
        delete this.register[field];
      });

      await UserModule.RegisterApplicant(this.register).then((response: any) => {
        if (response.autolog) {
          this.onLogin();
        } else {
          this.isSuccessRegister = true;
        }
      });
    } catch (error: any) {
      if (error.response.data.message === 'email already exists') this.isUsernameExist = true;
      if (error.response.data.message === 'username already exists') this.isUsernameExist = true;
      if (error.response.data.message === 'invalid params') this.isUsernameExist = true;
      this.$bvToast.toast(error.response.data.message, {
        title: 'Warning',
        variant: 'warning',
        autoHideDelay: 5000,
        solid: true,
      });
    }
  }

  @Watch('translations', { deep: true })
  handlerTranslation(newVal: any[]) {
    if (newVal && newVal[15008]) {
      this.$nextTick(() => {
        this.initializeModal();
      });
    }
  }

  initializeModal() {
    const termsLink = (this.$refs as any).content.querySelector('.terms-link');
    if (termsLink) {
      termsLink.addEventListener('click', (event: any) => {
        event.preventDefault();
        this.$bvModal.show('terms-modal');
      });
    }
  }

  async mounted() {
    this.initializeModal();
  }
}
</script>
