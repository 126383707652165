import { render, staticRenderFns } from "./JobComponentCluster.vue?vue&type=template&id=2203bd62&scoped=true"
import script from "./JobComponentCluster.vue?vue&type=script&lang=ts"
export * from "./JobComponentCluster.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2203bd62",
  null
  
)

export default component.exports