<template>
  <div class="job-component">
    <b-row align-v="end" class="mb-4 g-1" no-gutters>
      <b-col cols="12">
        <h4 v-html="jobsTitle"></h4>
      </b-col>
      <b-col cols="auto">
        <b-button @click="toProfessions()" variant="primary" size="sm" class="position-relative z-3" v-b-tooltip.hover :title="translations[13756]">
          <b-icon icon="chevron-left"></b-icon>
          <span class="ml-1 d-none d-md-inline-block">{{ translations[2901] }}</span>
        </b-button>
<!--        <b-button v-if="$route.name === 'Jobs'" size="sm" variant="primary" class="position-relative z-3 ml-1" v-b-toggle.jobs-filter>-->
<!--          <span v-b-tooltip.hover :title="translations[13590]">-->
<!--            <b-icon icon="sliders"></b-icon>-->
<!--            <span class="ml-1 d-none d-md-inline-block">{{ translations[13590] }}</span>-->
<!--          </span>-->
<!--        </b-button>-->
      </b-col>
<!--      <b-col cols="auto" class="ml-auto">-->
<!--        <b-button variant="primary" size="sm" class="position-relative z-3" v-b-modal="!isAuth ? 'not-auth-modal' : ''">-->
<!--          <b-icon icon="envelope"></b-icon>-->
<!--          <span class="ml-1 d-none d-md-inline-block">-->
<!--            {{ translations[10473] }}-->
<!--          </span>-->
<!--          <b-icon v-if="!isAuth" icon="shield-lock" class="ml-1"></b-icon>-->
<!--        </b-button>-->
<!--      </b-col>-->
    </b-row>
    <b-row class="mb-3" no-gutters>
      <b-col></b-col>
      <b-col cols="auto" class="d-flex align-items-center">
        <b-dropdown variant="primary" size="sm" class="rounded-0 rounded-start mr-1">
          <template v-slot:button-content>
            {{ paginationPerPage }}
          </template>
          <b-dropdown-item @click="[paginationPerPage = 20, jobsLoading()]">20</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 50, jobsLoading()]">50</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 100, jobsLoading()]" >100</b-dropdown-item>
        </b-dropdown>
        <b-pagination
          class="mb-0"
          size="sm"
          v-model="paginationPage"
          :total-rows="count"
          :per-page="paginationPerPage"
          @change="jobsLoading"/>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      variant="primary"
      :opacity="0.2"
      style="min-height: 200px"
    >
      <template v-if="jobs.length || loading">
        <job-item-cluster
          v-for="(item, index) in jobs"
          :key="index"
          :count="countNumber(index + 1)"
          :date="item.lastdate"
          :id="item.id"
          :company-id="item.company_id"
          :title="item.position"
          :profession="item.profession"
          :profession-code="item.profession_code"
          :description="item.location"
          :firm-name="item.company"
          :url="item.url"
          :is-auth="isAuth"
          :is-jobs-sidebar="true"
          :active="isNotebookItem(item.id).length"/>
      </template>
      <h5 v-else class="text-center">{{ translations[13786]}}</h5>
    </b-overlay>
    <b-row class="mt-3" no-gutters>
      <b-col></b-col>
      <b-col cols="auto" class="d-flex align-items-center">
        <b-dropdown variant="primary" size="sm" class="rounded-0 rounded-start mr-1">
          <template v-slot:button-content>
            {{ paginationPerPage }}
          </template>
          <b-dropdown-item @click="[paginationPerPage = 20, jobsLoading()]">20</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 50, jobsLoading()]">50</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 100, jobsLoading()]" >100</b-dropdown-item>
        </b-dropdown>
        <b-pagination
          class="mb-0"
          size="sm"
          v-model="paginationPage"
          :total-rows="count"
          :per-page="paginationPerPage"
          @change="jobsLoading"/>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { ClustersModule } from '@/store/modules/clusters';
import JobItemCluster from '@/components/Home/JobItemCluster.vue';
import { JobType } from '@/types/ProfessionType';
// eslint-disable-next-line import/named
import { NotebookModule } from '@/store/modules/notebook';
import { MapModule } from '@/store/modules/map';
import { ProfessionModule } from '@/store/modules/profession';
import { mixins } from 'vue-class-component';
import LocaleStringMixin from '@/mixins/LocaleStringMixin';
import { scrollToTop } from '@/helpers/interactivityHelpers';

@Component({
  components: {
    JobItemCluster,
  },
})
export default class JobComponentCluster extends mixins(LocaleStringMixin) {
  get translations() {
    return AppModule.translation;
  }

  loading = false;

  get jobsTitle() {
    return `${this.toLocaleLangString(this.count)} ${this.count === 1 ? this.translations[13772] : this.translations[13773]} ${this.professionTitle}`;
  }

  get jobs() {
    return ClustersModule.clusterJobs.jobs;
  }

  get jobType() {
    return ProfessionModule.jobType;
  }

  get isJobCluster() {
    return this.jobType === JobType.cluster;
  }

  get count() {
    return ClustersModule.clusterJobs.total_count;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  get professionTitle() {
    return ClustersModule.professionTitle;
  }

  get jobsMapIds() {
    return MapModule.selectedRegionJobsIds;
  }

  // pagination
  get paginationPage():number {
    return ClustersModule.jobClusterPagination.page;
  }

  set paginationPage(val) {
    ClustersModule.SetPagination({ obj: 'page', value: val });
  }

  get paginationPerPage():number {
    return ClustersModule.jobClusterPagination.per_page;
  }

  set paginationPerPage(val) {
    ClustersModule.SetPagination({ obj: 'per_page', value: val });
  }

  get notebookJobs() {
    return NotebookModule.notebookJobs;
  }

  // END pagination

  countNumber(count: number) {
    return (this.paginationPage - 1) * this.paginationPerPage + count;
  }

  isNotebookItem(value: number) {
    return this.notebookJobs.filter((x: { id: number }) => x.id === value);
  }

  async loadJobListItemsCluster() {
    this.loading = true;
    await ClustersModule.GetClusterJobs({ sid: this.languageSid });
    this.loading = false;
  }

  jobsLoading() {
    this.$nextTick(async() => {
      await this.loadJobListItemsCluster();
      setTimeout(() => {
        scrollToTop();
      }, 400);
    });
  }

  toProfessions() {
    AppModule.SetVisibleClusterJobs(false);
  }

  created() {
    this.jobsLoading();
  }
}
</script>
<style scoped lang="scss"></style>
