<template>
  <div v-if="isSuccessRegister">
    <h5>{{ translations[13898] }}</h5>
    <p>
      {{ translations[13899] }}
    </p>
    <p>{{ translations[13932] }}
      <a class="fw-bolder" href="#" @click.prevent="reloadTab">
        {{ translations[13933] }}
      </a>
    </p>
  </div>
  <b-form ref="registerForm" v-else autocomplete="new-password">
    <b-alert :show="isErrorsRegister" variant="danger">
      <span v-if="isUsernameExist">{{ translations[1283] }}</span>
      <span v-if="isEmailExist">{{ translations[10870] }}</span>
      <span v-if="isPrivacyError">{{ translations[14435] }}</span>
    </b-alert>
    <b-alert :show="isErrorsRegisterPassword" variant="danger">
      {{ translations[240] }}
    </b-alert>
    <b-form-group
      class="mb-1"
      id="input-company-1"
      label-for="company-username-register"
    >
      <b-form-input
        size="lg"
        class="border-0"
        id="company-username-register"
        v-model="register.username"
        type="text"
        autocomplete="new-password"
        :placeholder="translations[11001]"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mb-1"
      label-for="company-email-register"
    >
      <b-form-input
        size="lg"
        class="border-0"
        id="company-email-register"
        v-model="register.email"
        type="email"
        autocomplete="new-password"
        :placeholder="translations[5549]"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="isCompanyField(typeField.visible, regField.firmname)" class="mb-1" id="input-group-3" label-for="company-firmname-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="company-firmname-register"
        v-model="register.firmname"
        type="text"
        :placeholder="translations[407]"
        :required="isCompanyField(typeField.mandatory, regField.firmname)"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="isCompanyField(typeField.visible, regField.firstname)" class="mb-1" id="input-group-3" label-for="company-firstname-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="company-firstname-register"
        v-model="register.firstname"
        type="text"
        :placeholder="translations[234]"
        :required="isCompanyField(typeField.mandatory, regField.firstname)"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="isCompanyField(typeField.visible, regField.lastname)" class="mb-1" id="input-group-4" label-for="company-lastname-register">
      <b-form-input
        size="lg"
        class="border-0"
        id="company-lastname-register"
        v-model="register.lastname"
        type="text"
        :placeholder="translations[235]"
        :required="isCompanyField(typeField.mandatory, regField.lastname)"
      ></b-form-input>
    </b-form-group>
    <b-input-group
      class="mb-1"
      label-for="register-password-register"
    >
      <b-form-input
        size="lg"
        class="border-0"
        :placeholder="translations[10948]"
        name="company_password_register"
        id="company-password-register"
        v-model.trim="register.password"
        minlength="8"
        autocomplete="new-password"
        :type="passwordFieldType"
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
        :title="translations[2521]"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="custom-light"  @click="togglePasswordVisibility">
          <b-icon :icon="passwordFieldIcon"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group
      class="mb-1"
      label-for="company-password_repeat"
      :description="translations[2521]"
    >
      <b-form-input
        size="lg"
        class="border-0"
        :placeholder="translations[236]"
        name="company_password_repeat"
        id="company-password_repeat"
        v-model.trim="register.password_repeat"
        minlength="8"
        :type="passwordRepeatFieldType"
        autocomplete="new-password"
        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
        :title="translations[2521]"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-button variant="custom-light"  @click="togglePasswordRepeatVisibility">
          <b-icon :icon="passwordRepeatFieldIcon"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-row>
      <b-col cols="auto">
        <b-form-group class="mb-1" label-for="privacy-checkbox">
          <b-form-checkbox id="privacy-checkbox" v-model="isPrivacyAccepted" name="check-button" switch>
            <p class="fs-6 mb-0" ref="content" v-html="translations[15008]"></p>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { mixins } from 'vue-class-component';
import RegistrationFieldsMixin from '@/mixins/RegistrationFieldsMixin';
import { RegistrationField, TypeField } from '@/types/Login';

@Component
export default class RegisterCompanyForm extends mixins(RegistrationFieldsMixin) {
  isPasswordVisible = false;

  isPasswordRepeatVisible = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  get lang() {
    return AppModule.language;
  }

  get isInputToSid() {
    return ['talentpool', 'edunet'].includes(this.sid);
  }

  get policyLink() {
    return this.lang === 'en'
      ? 'https://www.workinaustria.com/en/talenthub-terms-of-use/'
      : 'https://www.workinaustria.com/nutzungsbedingungen/';
  }

  isPrivacyAccepted = false;

  get isErrorsRegister() {
    return this.isUsernameExist || this.isEmailExist || this.isPrivacyError;
  }

  get passwordFieldType() {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  get passwordFieldIcon() {
    return this.isPasswordVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  get passwordRepeatFieldType() {
    return this.isPasswordRepeatVisible ? 'text' : 'password';
  }

  get passwordRepeatFieldIcon() {
    return this.isPasswordRepeatVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordRepeatVisibility() {
    this.isPasswordRepeatVisible = !this.isPasswordRepeatVisible;
  }

  isErrorsRegisterPassword = false;

  isSuccessRegister = false;

  isPrivacyError = false;

  @Watch('isPrivacyAccepted')
  handler(val: boolean) {
    if (val) this.isPrivacyError = false;
  }

  @Watch('register.password')
  handlerPassword(val: boolean) {
    if (val) this.isErrorsRegisterPassword = false;
  }

  @Watch('register.password_repeat')
  handlerPasswordRepeat(val: boolean) {
    if (val) this.isErrorsRegisterPassword = false;
  }

  isEmailExist = false;

  isUsernameExist = false;

  register = {
    username: '',
    password: '',
    password_repeat: '',
    email: '',
    sid: this.sid,
    lang: this.lang,
    firstname: '',
    lastname: '',
    firmname: '',
  } as any;

  reloadTab() {
    this.$bvModal.hide('register-modal');
    this.$bvModal.show('login-modal');
  }

  resetErrors() {
    this.isPrivacyError = false;
    this.isEmailExist = false;
    this.isUsernameExist = false;
  }

  async onLogin() {
    try {
      const login = {
        username: this.register.username,
        password: this.register.password,
        sid: this.sid,
      };
      await UserModule.LoginCompany(login);
      await this.$router.push({
        name: 'Cabinet',
        query: { lang: this.lang },
      });
    } catch (error: any) {
      console.error(error);
    }
  }

  async onRegistration() {
    const form = this.$refs.registerForm as HTMLFormElement;
    if (!form.reportValidity()) {
      return;
    }
    const companyOptionalFields = [
      RegistrationField.firstname,
      RegistrationField.lastname,
      RegistrationField.firstname,
    ];

    companyOptionalFields.forEach((field) => {
      if (!this.isCompanyField(TypeField.visible, field)) {
        delete this.register[field];
      }
    });

    if (!this.isPrivacyAccepted) {
      this.isPrivacyError = true;
      return;
    }

    if (this.register.password !== this.register.password_repeat) {
      this.isErrorsRegisterPassword = true;
      return;
    }

    this.resetErrors();
    // this.isErrorsRegisterPassword = false;
    try {
      await UserModule.RegisterCompany(this.register).then((response: any) => {
        if (response.autolog) {
          this.onLogin();
        } else {
          this.isSuccessRegister = true;
        }
      });
    } catch (error: any) {
      if (error.response.data.message === 'email already exists') this.isEmailExist = true;
      if (error.response.data.message === 'username already exists') this.isUsernameExist = true;
      this.$bvToast.toast(error.response.data.message, {
        title: 'Warning',
        variant: 'warning',
        autoHideDelay: 5000,
        solid: true,
      });
    }
  }

  @Watch('translations', { deep: true })
  handlerTranslation(newVal: any[]) {
    if (newVal && newVal[15008]) {
      this.$nextTick(() => {
        this.initializeModal();
      });
    }
  }

  initializeModal() {
    const termsLink = (this.$refs as any).content.querySelector('.terms-link');
    if (termsLink) {
      termsLink.addEventListener('click', (event: any) => {
        event.preventDefault();
        this.$bvModal.show('terms-modal');
      });
    }
  }

  mounted() {
    this.initializeModal();
  }
}
</script>
